/* Utilities */

.grid-margin {
  margin-bottom: $card-spacing-y;
}

.grid-margin-sm-0 {
  @media (min-width: 576px) {
    margin-bottom: 0;
  }
}

.grid-margin-md-0 {
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}

.grid-margin-lg-0 {
  @media (min-width: 992px) {
    margin-bottom: 0;
  }
}

.grid-margin-xl-0 {
  @media (min-width: 1200px) {
    margin-bottom: 0;
  }
}

.img-lg {
  width: 92px;
  height: 92px;
}

.img-sm {
  width: 43px;
  height: 43px;

  &.rounded-10 {
    border-radius: 10px;
  }
}

.img-xs {
  width: 37px;
  height: 37px;
}

.img-ss {
  width: 35px;
  height: 35px;
}

.stretch-card {
  @include display-flex;
  @include align-items(stretch);
  @include justify-content(stretch);

  >.card {
    width: 100%;
    min-width: 100%;
  }
}

.border-right-sm {
  @media (min-width: 576px) {
    border-right: $border-width solid $border-color;
  }
}

.border-right-md {
  @media (min-width: 768px) {
    border-right: $border-width solid $border-color;
  }
}

.border-right-lg {
  @media (min-width: 992px) {
    border-right: $border-width solid $border-color;
  }
}

.border-left-sm {
  @media (min-width: 576px) {
    border-left: $border-width solid $border-color;
  }
}

.border-left-md {
  @media (min-width: 768px) {
    border-left: $border-width solid $border-color;
  }
}

.border-left-lg {
  @media (min-width: 992px) {
    border-left: $border-width solid $border-color;
  }
}

.text-gray {
  color: #8c8c8c;
}

.text-black {
  color: $black;
}

.text-small {
  font-size: 12px;

  &.ps-4 {
    padding-left: 30px !important;
  }
}

.flex-grow {
  flex-grow: 1;
}

.font-weight-light {
  font-weight: $font-weight-light;
}

.font-weight-bold {
  font-weight: $font-weight-bold;
}

.font-weight-normal {
  font-weight: normal;
}

.cursor-pointer {
  cursor: pointer;
}

.image-grouped {
  display: flex;

  .text-avatar,
  img {
    @extend .img-ss;
    border-radius: 100%;
    margin-left: -10px;
    z-index: 0;
    border: 4px solid $card-bg;
    transform: scale(1);
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-property: "box-shadow", "z-index", "transform", "border-width";

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      z-index: 1;
      box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
      transform: scale(1.05);
      border-width: 0;

    }
  }

  .text-avatar {
    @extend .bg-inverse-primary;
    color: $primary;
    font-size: 11px;
    font-weight: 600;
  }
}

.dot-indicator {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.aligner-wrapper {
  position: relative;

  .absolute {
    position: absolute;

    &.absolute-center {
      top: 50%;
      transform: translateY(-50%);
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    &.bottom {
      bottom: 0;
    }

    &.top {
      top: 0;
    }
  }
}

.v-strock-2 {
  width: 3px;
}

.min-width-cell {
  min-width: 110px;
}

.font-weight-medium {
  font-weight: 500;
}

.media {
  display: flex;
}

.settings-nav-bottom {
  position: fixed;
  bottom: 10px;
  width: 220px;
}

.h-250 {
  height: 25rem;
  overflow-y: auto;
}

.list.active {
  background: #ffe2e2;
  box-shadow: 0 3px 4px #c2c2c2;
}

.list {
  .dropdown {
    .dropdown-toggle:after {
      content: none;
    }
  }
}

.z-0 {
  z-index: 0 !important;
}

.shadow {
  box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
}

.MuiTablePagination-displayedRows,
.MuiTablePagination-selectLabel {
  margin: 0;
}

.MuiTypography-root {
  font-family: Manrope, sans-serif;
}

.modal {
  --bs-modal-width: 800px;
}

.zoom-on-hover {
  transition: transform 0.3s ease-in-out;
  /* Smooth transition effect */
}

.zoom-on-hover:hover {
  transform: scale(1.05);
  /* Zoom in 1.1 times */
}


.animate-col-md-6 {
  transition: width 0.3s ease-in-out;
  /* Add transition property for width */
}

.animate-d-none {
  width: 0;
  /* Set width to 0 when hidden */
  overflow: hidden;
  /* Hide the content when width is 0 */
}

tr.MuiTableRow-root {
  height: inherit !important;
}

.table td {
  text-wrap: initial !important;
}

.header-blue {

  background: #3771be;
  color: white;

}

.body-blue {
  background: #fff;
}

.not-print .body-blue {
  background: #fff;
}

.header-logo {
  background-color: white;
  padding: 1rem !important;
  border-radius: 10px;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.white-bg-border-radius-10 {
  background-color: white;
  border-radius: 10px;
}

.form-control-plaintext {
  font-size: 16px;
  padding: 0.175rem;
  margin-bottom: 10px;
}

.img-checkbox {
  position: absolute;
  top: 30px;
  right: 0;
}

.font-lg {
  font-size: 18px !important;
}

.table thead th,
.table tbody td,
.table tbody th,
.table tr,
.table td,
.table th,
.text-16 {
  padding: 4px !important;
  font-size: 16px !important;
}

.text-16 {
  font-size: 16px;
}

.table td ul {
  margin: 0px;
}

.react-photo-gallery--gallery>div>img {
  flex-flow: nowrap !important;
  width: 100%;
  // height: 150px;
}

.active-card {
  border: 2px solid #193A79 !important;
  background-color: #f0f8ff;
}

// .card-border {
//   border: 2px solid transparent !important;
// }

.p-accordion-header-link{
  text-decoration: none !important;
}
.MuiDropzoneArea-root {

  min-height: 150px;
} 

.table ul li,
.table ol li {
  font-size: 16px;
}

input.form-control {
  height: 38px;
  margin-bottom: 0px;
}

.m-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.not-print .col-form-label {
  font-size: 12px;
  font-weight: bold;
}

.not-print .table-header-sm tr th {
  font-size: 12px !important;
  font-weight: bold !important;
}

.not-print .padding-table.table tr {
  padding: 9px !important;
  font-size: 16px !important;
}

.not-print .text-strong {
  font-weight: bold;
}

.react-select__control {
  margin-bottom: 15px;
}

.react-pdf__Page {
  border-bottom: 7px solid #f4f5f7;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

.react-pdf__Page {
  background-color: #f4f5f7 !important;
}

.h-250 {
  height: 25rem;
  overflow-y: auto;
}

.h-250::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #F5F5F5;
}

.h-250::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.h-250::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #1F3BB3;
}

.total-value {
  text-align: right;
  margin-right: 231px;
}

.medium-modal .modal-lg {
  --bs-modal-width: 50% !important;
}

.obs-content,
.obs-title {
  font-size: 16px;

  font-weight: bold;
}

.obs-title {
  color: darkgray;
}

.obs-header {
  background-color: #F9FAFB;
}

.obs-section {
  border-bottom: 1px solid #eee;
}

.font-sm.table tbody td,
.font-sm.table thead th {
  font-size: 12px !important;
}

.nested-modal {
  --bs-backdrop-zindex: 1055;
  z-index: 1055;
}

.required:after {
  content: " *";
  color: red;
}


.pack-container {
  margin: 20px auto;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.pack-header {

  align-items: center;
  border-bottom: 2px solid #007BFF;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.pack-header-image {
  width: 150px;
  height: auto;
  margin-right: 20px;
}

.pack-header-text {
  flex: 1;
}

.pack-header-text h1 {
  margin: 0;
  font-size: 1.2em;
  color: #007BFF;
}

.pack-header-text p {
  margin: 5px 0;
  font-size: 0.9em;
}

.pack-content {
  margin-top: 20px;
}

.pack-section {

  padding: 10px;
  background-color: #ffffff;
}

.pack-section h2 {
  margin-bottom: 10px;
  font-size: 1em;
  color: #007BFF;
}

.pack-section ul {
  padding-left: 20px;
  font-size: 0.9em;
}

.pack-section ul li {
  margin-bottom: 10px;
}

.pack-risk-level {
  font-weight: bold;
  color: red;
  font-size: 0.9em;
}

.MuiAccordion-root {
  background: #003b785c !important;
}

.MuiCollapse-root {
  background: white !important;
  border-radius: 10px;
  padding-top: 10px;
  margin-bottom: 15px;

}

label.checkbox-bootstrap input[type=checkbox] {
  /* hide original check box */
  opacity: 0;
  position: absolute;
  /* find the nearest span with checkbox-placeholder class and draw custom checkbox */
  /* draw checkmark before the span placeholder when original hidden input is checked */
  /* disabled checkbox style */
  /* disabled and checked checkbox style */
  /* when the checkbox is focused with tab key show dots arround */
}

label.checkbox-bootstrap input[type=checkbox]+span.checkbox-placeholder {
  width: 14px;
  height: 14px;
  border: 1px solid;
  border-radius: 3px;
  /*checkbox border color*/
  border-color: #737373;
  display: inline-block;
  cursor: pointer;
  margin: 0 7px 0 7px;
  vertical-align: middle;
  text-align: center;
}

label.checkbox-bootstrap input[type=checkbox]:checked+span.checkbox-placeholder {
  background: linear-gradient(0deg, #315975, #315975), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

label.checkbox-bootstrap input[type=checkbox]:checked+span.checkbox-placeholder:before {
  display: inline-block;
  position: relative;
  vertical-align: text-top;
  width: 5px;
  height: 9px;
  /*checkmark arrow color*/
  border: solid white;
  border-width: 0 2px 2px 0;
  /*can be done with post css autoprefixer*/
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
}

label.checkbox-bootstrap input[type=checkbox]:disabled+span.checkbox-placeholder {
  background: #ececec;
  border-color: #c3c2c2;
}

label.checkbox-bootstrap input[type=checkbox]:checked:disabled+span.checkbox-placeholder {
  background: #d6d6d6;
  border-color: #bdbdbd;
}

label.checkbox-bootstrap input[type=checkbox]:focus:not(:hover)+span.checkbox-placeholder {
  outline: 1px dotted black;
}

label.checkbox-bootstrap.checkbox-lg input[type=checkbox]+span.checkbox-placeholder {
  width: 24px;
  height: 24px;
  border: 1px solid;
  border-image-source: linear-gradient(0deg, #315975, #315975), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  border-radius: 4px;
  /*checkbox border color*/
  border-color: #737373;
}

label.checkbox-bootstrap.checkbox-lg input[type=checkbox]:checked+span.checkbox-placeholder:before {
  width: 9px;
  height: 15px;
  /*checkmark arrow color*/
  border: solid white;
  border-width: 0 3px 3px 0;
}

.table td img {
  width: unset !important;
  height: unset !important;
}

/* Add this to your CSS file */
.p-radiobutton-box.p-highlight::before {
  content: "✔";
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.p-radiobutton-box {
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 50%;
}

.p-radiobutton-box.p-highlight {
  background-color: #4caf50;
  border-color: #4caf50;
}

.p-radiobutton-box .p-radiobutton-icon {
  display: none;
}

.task-tree {
  margin-left: 20px;
  padding-left: 20px;
  border-left: 2px solid #ccc;
  position: relative;
}

.tree-item {
  margin: 10px 0;
  padding-left: 20px;
  position: relative;
}

.tree-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: -20px;
  width: 20px;
  height: 50%;
  border-top: 2px solid #ccc;
}

.tree-item::after {
  content: '';
  position: absolute;
  top: 50%;
  left: -20px;
  width: 20px;
  height: 50%;
  border-bottom: 2px solid #ccc;
}

.tree-branch {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.tree-input {
  flex-grow: 1;
  margin-right: 10px;
}

.tree-remove-btn {
  margin-left: 10px;
}

.small-dropzone {
  min-height: 50px;
  /* Adjust the height as needed */
  padding: 10px;
  /* Adjust padding */
  border: 2px dashed #cccccc;
  /* Customize border */
  border-radius: 5px;
  /* Optional: Add rounded corners */
  text-align: center;
}

.small-dropzone .MuiTypography-root {
  font-size: 0.9rem;
  /* Adjust text size */
}

.small-dropzone .MuiSvgIcon-root {
  font-size: 1.5rem;
  /* Adjust icon size */
}


.strike-through {
  text-decoration: line-through;
  color: #6c757d;
  /* Optional: make the color lighter when checked */
}

.task-details-container {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #dee2e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.task-details-container h5 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #343a40;
  margin-bottom: 10px;
}

.task-details-container p {
  font-size: 1rem;
  color: #495057;
  margin-bottom: 10px;
}

.task-details-container .priority-low {
  color: #28a745;
  font-weight: bold;
}

.task-details-container .priority-medium {
  color: #ffc107;
  font-weight: bold;
}

.task-details-container .priority-high {
  color: #dc3545;
  font-weight: bold;
}

.tasks-container {
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #dee2e6;
  margin-top: 15px;
}

.tasks-container h6 {
  font-size: 1.25rem;
  font-weight: bold;
  color: #343a40;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.tasks-container .form-check {
  margin-bottom: 10px;
}

.tasks-container .form-check-label {
  font-size: 1rem;
  color: #495057;
}

.tasks-container .form-check-input:checked {
  background-color: #007bff;
  border-color: #007bff;
}

.tasks-container .form-check-input {
  cursor: pointer;
  width: 1.2rem;
  height: 1.2rem;
}

.custom-progress-bar {
  border-radius: 10px;
  /* Rounded corners */
  height: 10px;
  /* Height of the progress bar */
}



.custom-progress-bar-completed .progress-bar {
  background-color: #1f845a !important;
  /* Green color for completed */
}

.custom-progress-bar-empty {
  background-color: #091e420f;
  /* Grey color for empty progress */
}

.custom-progress-container {
  background-color: #f8f9fa;
  /* Light grey background */
  border-radius: 10px;
  padding: 5px;
  /* Optional padding around the progress bar */
}

.custom-progress-label {
  font-size: 0.875rem;
  /* Smaller font size */
  color: #6c757d;
  /* Grey color */
  margin-bottom: 5px;
  /* Space between label and progress bar */
  display: flex;
  justify-content: flex-end;
  /* Align label to the right */
}

.custom-switch {
  display: flex;
  align-items: center;
}

.custom-switch .p-inputswitch {
  width: 60px;
  height: 30px;
  background-color: #fff;
  /* Background for the 'No' state */
  border-radius: 15px;
  position: relative;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}

.custom-switch .p-inputswitch .p-inputswitch-slider {
  background-color: #1A237E;
  /* Blue color for the 'Yes' state */
  border-radius: 15px;
  height: 100%;
  width: 50%;
}

.custom-switch span {
  margin-left: 10px;
  font-size: 16px;
  color: #1A237E;
  /* Adjust color for the text */
}

.status-badge {
  padding: 0.3em 0.6em;
  font-size: 0.875em;
  font-weight: 600;
  border-radius: 0.5em;
  display: inline-block;
  margin-left: auto;
  text-align: center;
  white-space: nowrap;
}

.status-completed {
  background-color: #28a745;
  color: white;
}

.status-inprogress {
  background-color: #ffc107;
  color: white;
}

.status-onhold {
  background-color: #dc3545;
  color: white;
}

.status-waiting {
  background-color: #17a2b8;
  color: white;
}

.status-yettostart {
  background-color: #6c757d;
  color: white;
}

.strike-through {
  text-decoration: line-through;
}

.percentage-slider {
  width: 100%;
  height: 5px;
  appearance: none;
  background: #e9ecef;
  border-radius: 5px;
  outline: none;
}

.percentage-slider::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #007bff;
  cursor: pointer;
}

.percentage-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #007bff;
  cursor: pointer;
}

.percentage-slider::-ms-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #007bff;
  cursor: pointer;
}

.boxEffective {
  background-color: lightgreen;
  border-color: green;
}

.boxUnEffective {
  background-color: #f5f5f5;
  border-color: gray;
}

.radioEffective {
  color: green;
}

.radioUnEffective {
  color: gray;
}

.incomplete-stage {
  background-color: orange !important;
  /* Gray for inactive steps */
  color: white;
}

.incident-step.completed-stage.active,
.incident-step.incompleted-stage.active {
  border: 2px solid #000 !important;
}


.stepper-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.stepper-step {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  cursor: pointer;
}

.stepper-step .circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: gray;
  /* Default color */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
  z-index: 1;
}

.stepper-step .circle.active {
  background-color: #007bff;
  ;
}

.stepper-step .circle.incomplete-stage {
  background-color: orange;
  color: #fff;
}

.stepper-step .circle.completed-stage {
  background-color: #28a745;
  color: #fff;
}

.stepper-step .circle.not-started {
  background-color: lightgray;
}

.stepper-step .step-title {
  margin-top: 5px;
  font-size: 14px;
  height: 50px;
}

.stepper-step .step-title.active {
  font-weight: bold;
}

.stepper-step .line {
  position: absolute;
  top: 20px;
  /* Align with the middle of the circle */
  left: 50%;
  height: 2px;
  width: 100%;
  background-color: #ccc;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stepper-step .line::before {
  content: '';
  position: absolute;
  left: 0;
  width: 50%;
  height: 2px;
  background-color: #ccc;
}

.stepper-step .line::after {
  content: '>';
  /* Right arrow */
  font-weight: bold;
  font-size: 22px;
  color: #6e6e6e;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  // background: white;
  padding: 0 5px;
}

.stepper-step:last-child .line {
  display: none;
  /* Hide the line after the last step */
}

.step-title.active {
  color: #007bff;
  text-decoration: underline;
}

.side-modal .p-dialog {
  background-color: #f0f4f8;
  /* Light blue-gray background */
  border-radius: 8px;
  /* Optional: Add some border-radius for a softer look */
}

.side-modal .p-dialog-header {
  background-color: #007ad9;
  /* A darker blue for the header */
  color: white;
  /* White text color for contrast */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.side-modal .p-dialog-footer {
  background-color: #f0f4f8;
  /* Match the body background */
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.side-modal .p-dialog-content {
  background-color: #f0f4f8;
  /* Match the body background */
}

.red-background {
  background-color: #ffcccc !important;
  /* Light red background */
}

.custom-accordion-header {

  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
  border: none;
  /* Remove any default border */
}

.custom-accordion-header.identified {
  background-color: #d4edda !important;
  /* light green */
  color: #155724 !important;
  /* text color for better visibility */
  border: 1px solid #c3e6cb;
}

.custom-accordion-header.unidentified {
  background-color: #f8d7da !important;
  /* light red */
  color: #721c24 !important;
  /* text color for better visibility */
  border: 1px solid #f5c6cb;
}

.custom-accordion-header:hover {
  background-color: #f5f5f5 !important;
  /* Darker blue on hover */
  transition: 1s all;
}

.custom-accordion-header button {
  background-color: transparent !important;
  padding: 10px !important;
}

.bg-purple {
  background-color: #6f42c1;
  color: white;
}

.bg-light.text-dark {
  background-color: #e9ecef;
  color: #343a40;
}

.styled-table {
  width: 100%;
  margin: 20px 0;
  border-collapse: collapse;
  font-size: 16px;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
  border: 1px solid #dddddd;
  text-align: left;
}

.styled-table thead tr {
  background-color: #f4f4f4;
}

.styled-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.styled-table tbody tr:hover {
  background-color: #f1f1f1;
}

/* ControlsFormModal.css */

/* Accordion Header Colors */
/* Accordion Header Styling */
.accordion-header-1 {
  background-color: #f8d7da !important;
  /* Light Red */
  color: #721c24;
  /* Dark Red Text */

}

.accordion-header-2 {
  background-color: #d1ecf1 !important;
  /* Light Blue */
  color: #0c5460;
  /* Dark Blue Text */

}

.accordion-header-3 {
  background-color: #d4edda !important;
  /* Light Green */
  color: #155724;
  /* Dark Green Text */

}

.accordion-header-4 {
  background-color: #fff3cd !important;
  /* Light Yellow */
  color: #856404;
  /* Dark Yellow Text */

}

.accordion-header-5 {
  background-color: #e2d3f3 !important;
  /* Light Purple */
  color: #5a3d87;
  /* Dark Purple Text */

}

/* Numbering Style */
.accordion-number {
  margin-right: 8px;
  font-weight: bold;
  color: inherit;
}

/* Card Styling */
.custom-card {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}

/* Section Title Styling */
.section-title {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

/* Button Styling */
.btn-success {
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  background-color: #218838;
  border-color: #1e7e34;
}

/* Optional: Add transition effects */
.accordion-header-1,
.accordion-header-2,
.accordion-header-3,
.accordion-header-4,
.accordion-header-5 {
  transition: background-color 0.3s ease;
}

.accordion-header-1:hover,
.accordion-header-2:hover,
.accordion-header-3:hover,
.accordion-header-4:hover,
.accordion-header-5:hover {
  filter: brightness(90%);
}


.invert button {
  background: transparent !important;
}

.color {
  display: flex;
  align-items: center;
}

.instruction-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
}

.color .ms-2 {
  margin-left: 8px;
  /* Adjust the spacing between the circle and the text */
}

/* Custom styling for the confirm button */
.custom-confirm-button {
  background-color: #28a745 !important;
  /* Green color */
  border-color: #28a745 !important;
  /* Green border */
  color: #fff !important;
  /* White text */
}

.custom-tab {
  padding: 10px 20px !important;
  border: 2px solid transparent !important;
  border-radius: 5px !important;
  transition: border-color 0.3s ease;
}

.custom-tab.active {
  border-color: #007bff !important;
  /* Change this color to your desired border color */
  color: unset !important;
}

.custom-tab:hover {
  border-color: #007bff !important;
  /* Optional: change border on hover */
  color: unset !important;
}

a.jodit-status-bar-link {
  display: none;
}

.number-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  font-size: 14px;
}

/* Default radio button */
.custom-radio .p-radiobutton-box {
  border-color: #ced4da;
}

/* Completed (green) radio button */
.radio-green .p-radiobutton-box {
  border-color: green !important;
  background-color: green !important;
}

/* In progress (yellow) radio button */
.radio-yellow .p-radiobutton-box {
  border-color: orange !important;
  background-color: orange !important;
}

/* Optional: Styling for unchecked radio button */
.custom-radio .p-radiobutton-box:not(.p-highlight):hover {
  border-color: #ced4da;
}

.trash-icon-container {
  background-color: red;
  border-radius: 50%;
  /* Makes the background circular */
  color: white;
  /* Makes the icon white */
  cursor: pointer;
  /* Changes the cursor to pointer */
  width: 40px;
  /* Ensures consistent width */
  height: 40px;
  /* Ensures consistent height */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  /* Optional: smooth hover effect */
}

.trash-icon-container:hover {
  background-color: darkred;
  /* Optional: darker red on hover */
}

th.risk-th>div {
  display: flex;
  justify-content: center;
}

.redBox {
  background-color: #fea9a4;
  border-radius: 5px;
  color: #e42120;
  padding: 5px 10px;
  text-decoration: none;
}

.greenBox,
.orangeBox {
  border-radius: 5px;
  padding: 5px 10px;
  text-decoration: none;
}

.greenBox {
  background-color: #92d14f;
  color: #3e7a18;
}

.orangeBox {
  background-color: #ffde91;
  color: #ce823c;
}

.status-tag-blue {
  background: #bdd1f780;
  color: #5b8ff7;
}

.permit-head {
  padding: 10px;
  text-align: center;
  background: #ff0c006b;
  color: #fff;
  font-weight: 600;
}

.card-eptw {
  background: #bcc4ff;
  border-radius: 50px;
  color: #2323d7;
  font-size: 14px;
  padding: 3px 8px;
}

.bordered-table .p-datatable-thead>tr>th,
.bordered-table .p-datatable-tbody>tr>td {
  border: 1px solid #dee2e6;
  /* Light grey border */
}

.bordered-table .p-datatable-tbody>tr {
  border-bottom: 1px solid #dee2e6;
  /* Adds bottom border to each row */
}

.bordered-table .p-datatable {
  border-collapse: collapse;
  /* Ensures borders collapse for cleaner look */
}

.grey-background-modal {
  background-color: #f8f9fabd;

}

.my-tabs ul.p-tabview-nav{
  margin-bottom: 0;
  padding: 0;
}
.my-tabs ul.p-tabview-nav li{
  width: 100%;
}

.my-tabs ul.p-tabview-nav li a{
  display: flex;
  justify-content: center;
  text-decoration: none;
  padding: 10px;
}
.widget-name{
  font-size: 14px;
}

// .content-wrapper{
//   background: #e5eeff!important;
// } 

.custom-button-style {
  text-decoration: none;
  // border: 1px solid;
  font-size: 11px;
}

.active-tab {
  background-color: #f3f2ff !important ; /* Light purple background */
  border-radius: 20px !important; /* Make the button pill-shaped */
}
.unactive-tab{
  background: none;
  border:none;

}


.observation-report-modal .modal-dialog {
  max-width: 700px; /* Increase width if you want a larger modal */
}

.observation-report-modal .modal-content {
  border-radius: 8px;
  padding: 1rem;
}

.observation-report-modal .modal-header {
  border-bottom: none; /* Remove header border if you like */
}

.observation-report-modal .modal-body {
  padding: 1.5rem 2rem; /* Increase padding inside the modal body */
}

.observation-report-modal .form-group {
  margin-bottom: 1.25rem;
}

.observation-report-modal .toggle-button-group {
  margin-top: 0.5rem;
  margin-bottom: 1.25rem;
}

.observation-report-modal .dropzone-container {
  margin-bottom: 1.25rem;
  padding: 1rem;
  border: 1px dashed #ccc;
  border-radius: 6px;
}

/* Improve spacing around "Uploaded Documents" section */
.observation-report-modal .image-preview .row {
  margin-top: 0.75rem;
  margin-bottom: 1rem;
}

/* Add a nice subtle shadow to image boxes */
.observation-report-modal .boxShadow {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  border-radius: 4px;
  position: relative;
}

/* Optional: style for the trash icon */
.observation-report-modal .pi-trash {
  color: #dc3545;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.observation-report-modal .pi-trash:hover {
  color: #bd2130;
}

/* Example styling for the toggle buttons to give them more uniform spacing */
.observation-report-modal .btn-toggle + .btn-toggle {
  margin-left: 0.5rem;
}

/* 
   ========== Toggle Button Styles ==========
   We'll enhance the appearance of the toggle buttons here.
*/

/* Container for the ButtonGroup */
.observation-report-modal .toggle-button-group {
  margin-top: 0.5rem;
  margin-bottom: 1.25rem;
  display: flex;
  gap: 0.5rem; /* Gap between buttons */
}

/* Individual Toggle Button (unified class for styling) */
.observation-report-modal .btn-toggle {
  border-radius: 50px;         /* Pill shape */
  padding: 0.5rem 1rem;        /* Increase padding for a nicer look */
  font-weight: 500;            /* Make text a bit bolder */
  transition: background-color 0.2s ease, color 0.2s ease;
}

/* 
   For the "outline" variant, let's give it a more refined look:
   - a thin border
   - subtle background on hover
*/
.observation-report-modal .btn-toggle.btn-outline-success,
.observation-report-modal .btn-toggle.btn-outline-danger,
.observation-report-modal .btn-toggle.btn-outline-primary {
  border-width: 2px;
}

.observation-report-modal .btn-toggle.btn-outline-success:hover,
.observation-report-modal .btn-toggle.btn-outline-danger:hover,
.observation-report-modal .btn-toggle.btn-outline-primary:hover {
  background-color: #f8f9fa;  /* Light gray hover */
}

/* 
   If you want a consistent “active” look, 
   customize the .active class or the .show > .btn-toggle styles 
*/
.observation-report-modal .btn-toggle.active,
.observation-report-modal .btn-toggle:active,
.observation-report-modal .btn-toggle:focus,
.observation-report-modal .btn-toggle:focus:active {
  outline: none;
  box-shadow: none;
}

// .observation-report-modal .btn-toggle:hover {
//   background-color: #eaeaea; /* subtle hover color */
// }


